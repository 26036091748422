import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { SwapWidget } from '@uniswap/widgets';

import '@uniswap/widgets/fonts.css';
import "./PublicSale.css";

import { TOKEN_ADDRESS, WALLET_TO_TRACK, ERC20_ABI, TOKEN_SUPPLY } from '../../CONSTANTS';

const PublicSale = () => {
    const [currentTokenSupply, setCurrentTokenSupply] = useState([]);
    const [percentageSold, setPercentageSold] = useState(0);
    const [tokensPurchased, setTokensPurchased] = useState(0);
  
    // Dummy data
    let tokenSupply = TOKEN_SUPPLY;
    const MY_TOKEN_LIST = [
      {
      "name": "Blue Social Token",
      "address": "0x0b3eE85E3419ed38A7D00a56010237aA9a712CdC",
      "symbol": "BLUE",
      "decimals": 18,
      "chainId": 1,
      // "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
    },];
    const NATIVE = 'NATIVE';

    const fetchTokenBalance = async () => {
        const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/4f2f29a725e14b65bb1e5efdb7410fec');
        const tokenContract = new ethers.Contract(TOKEN_ADDRESS, ERC20_ABI, provider);
    
        try {
          const balance = await tokenContract.balanceOf(WALLET_TO_TRACK);
          const formattedBalance = ethers.utils.formatUnits(balance, 18); 

          // console.log("Balance:", balance);
          // console.log("Formatted Balance:", String(formattedBalance));
    
          setTokensPurchased(parseFloat(formattedBalance)); 
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      };
    
      useEffect(() => {
        const calculateSupply = () => {
          let remainingTokens = tokensPurchased;
          let formattedSupply = remainingTokens.toLocaleString('en-US');
          let tokenArray = Array.from(formattedSupply);
    
          setCurrentTokenSupply(tokenArray);
        };
    
        fetchTokenBalance().then(() => {
          calculateSupply(); 
        });
    
      }, [tokensPurchased]); 
    
      useEffect(() => {
        let percentSold = ((tokensPurchased / tokenSupply) * 100).toFixed(2);
        setPercentageSold(percentSold);
      }, [tokensPurchased]);

  return (
    <div id="buy-tokens"> 
    <div className="container-width section-space">
      <h1 className="platfrom-heading">
        JOIN THE BLUE SOCIAL TOKEN SALE ON UNISWAP!
      </h1>
      <div className="num-main">
        <div className="number-flex">
          <div className="num-sec">
            {currentTokenSupply.map((item, i) => (
              <React.Fragment key={i}>
                {item === ',' ? (
                    <p className='coma'>,</p>
                ) : (
                  <p className="single-number">{item}</p>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="loading-bar-container">
          <div className="loading-bar" style={{ width: `${percentageSold}%` }}></div>
        </div>
        <p>
            <span className="blue-text">{percentageSold}%</span> of Blue Social Tokens remaining for purchase
        </p>
      </div>

      <div className="Uniswap">
            <SwapWidget 
            tokenList={MY_TOKEN_LIST}
            defaultInputTokenAddress={NATIVE}
            defaultOutputTokenAddress="0x0b3eE85E3419ed38A7D00a56010237aA9a712CdC"
            />
        </div>
    </div>
    </div>
  );
};

export default PublicSale;
