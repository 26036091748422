import React from "react";
import "./Platfroms.css";
import Draper from "../../assets/img/draper.png";
import NewYork from "../../assets/img/nytimes.png";
import Forbes from "../../assets/img/forbes.png";
import Yahoo from "../../assets/img/yahoo.png";
import Uni from "../../assets/img/univision.png";
import Abc from "../../assets/img/abc.png";

const Platfroms = () => {
  return (
    <div className="section-space text-center container-width">
      <h1 className="platfrom-heading ">As seen on</h1>
      <div className="social-flex">
        <a href="https://www.draper.vc/companies/blue" className="single-social" target="_blank" rel="noopener noreferrer">
          <img src={Draper} alt="Draper" />
        </a>
        <a  className="single-social" rel="noopener noreferrer">
          <img src={NewYork} alt="New York Times" />
        </a>
        <a  className="single-social" rel="noopener noreferrer">
          <img src={Forbes} alt="Forbes" />
        </a>
        <a href="https://finance.yahoo.com/news/billionaire-tim-draper-invests-1-183000432.html" className="single-social" target="_blank" rel="noopener noreferrer">
          <img src={Yahoo} alt="Yahoo" />
        </a>
        <a  className="single-social" rel="noopener noreferrer">
          <img src={Uni} alt="Univision" />
        </a>
        <a className="single-social" rel="noopener noreferrer">
          <img src={Abc} alt="ABC" />
        </a>
      </div>
    </div>
  );
};

export default Platfroms;
